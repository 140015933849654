import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import jquery from 'jquery';
import slick from 'slick-carousel';
import AOS from 'aos';

window.$ = window.jQuery = jquery;

$(function ($) {
    // Slick Slider Images
    $(".slideshow").slick({
        infinite: false,
        autoplay: false,
        dots: false,
        arrows: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
    });

    // Floating links
    $(document).ready(function () {
        // Cache selectors
        var $links = $(".floating-links a");
        var $sections = $("section");

        // Function to update active link
        function updateActiveLink() {
            var scrollPosition = $(window).scrollTop() + $(window).height() / 2;

            $sections.each(function () {
                var $section = $(this);
                var sectionTop = $section.offset().top;
                var sectionBottom = sectionTop + $section.outerHeight();

                if (
                    scrollPosition >= sectionTop &&
                    scrollPosition <= sectionBottom
                ) {
                    var id = $section.attr("id");
                    $links.removeClass("active");
                    $('.floating-links a[href="#' + id + '"]').addClass(
                        "active"
                    );
                }
            });
        }

        // Initial check
        updateActiveLink();

        // On scroll event
        $(window).on("scroll", function () {
            updateActiveLink();
        });

        // Add fullscreen functionality to model-viewer
        $(".model-viewer").on("click", function () {
            if (!$(this).hasClass("fullscreen")) {
                toggleModelFullscreen();
            }
        });

        // Handle close button click
        $(".fullscreen-close").on("click", function (e) {
            e.stopPropagation(); // Prevent triggering model-viewer click
            toggleModelFullscreen();
        });

        // Add event listeners for user interaction
        controls.addEventListener("start", function () {
            userInteracted = true;
        });
    });

    // Change mouse cursor on Model section hover
    $(document).ready(function () {
        const $cursor = $("#model-custom-cursor");
        const offset = 30;
        let cursorVisible = false;

        // Update cursor position
        function updateCursorPosition(e) {
            const mouseY = e.clientY;
            const mouseX = e.clientX;

            $cursor.css({
                transform: `translate(${mouseX - offset}px, ${
                    mouseY - offset
                }px)`,
            });
        }

        // Show custom cursor on .model-section hover
        $(".model-section")
            .on("mouseenter", function () {
                cursorVisible = true;
                $cursor.addClass("active");
                $("body").css("cursor", "none"); // Hide the default cursor
            })
            .on("mouseleave", function () {
                cursorVisible = false;
                $cursor.removeClass("active");
                $("body").css("cursor", "auto"); // Restore the default cursor
            });

        // Track mouse movements globally
        $(document).on("mousemove", function (e) {
            updateCursorPosition(e);
        });
    });

    // AOS animations
    AOS.init({
        duration: 1200,
        once: true,
    });

    // If escape key is pressed and the model is in fullscreen, close it
    document.addEventListener("keydown", (e) => {
        if (e.key === "Escape" && $(".model-viewer").hasClass("fullscreen")) {
            toggleModelFullscreen();
        }
    });

    // Toggle model fullscreen handling
    function toggleModelFullscreen() {
        if ($(".model-viewer").hasClass("fullscreen")) {
            $("body").removeClass("no-scroll");
            $(".model-section .container-fluid").append($(".model-viewer"));
            $(".model-viewer").removeClass("fullscreen");
            $(".fullscreen-close").hide();
            controls.enabled = false; // Disable controls when not in fullscreen
            userInteracted = false; // Reset user interaction flag
        } else {
            $("body").append($(".model-viewer")).addClass("no-scroll");
            $(".model-viewer").addClass("fullscreen");
            $(".fullscreen-close").show();
            controls.enabled = true; // Enable controls in fullscreen
            userInteracted = false; // Reset user interaction flag

            // Reset camera position and controls target
            camera.position.copy(originalCameraPosition);
            controls.target.copy(originalControlsTarget);
            controls.update();
        }

        onWindowResize();
    }

    // Model Rendering Setup
    const container = document.querySelector(".model-viewer");
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
        100,
        container.clientWidth / container.clientHeight,
        0.1,
        1000
    );
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(container.clientWidth, container.clientHeight);
    container.appendChild(renderer.domElement);

    renderer.setClearColor(0x111111, 1);
    renderer.setPixelRatio(window.devicePixelRatio);

    let originalCameraPosition;
    let originalControlsTarget;

    const params = {
        ambientLightIntensity: 1.5,
        pointLightIntensity: 1.5,
    };

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.03;
    controls.enablePan = false;
    controls.maxPolarAngle = Math.PI / 2 + 0.25;
    controls.minPolarAngle = Math.PI / 2 - 0.25;
    controls.maxAzimuthAngle = Math.PI / 2 - 0.55;
    controls.minAzimuthAngle = -Math.PI / 2 + 0.55;
    controls.enabled = false; // Disable controls by default
    controls.enableZoom = true;

    const ambientLight = new THREE.AmbientLight(
        0xffffff,
        params.ambientLightIntensity
    );
    scene.add(ambientLight);

    const pointLight = new THREE.PointLight(
        0xffffff,
        params.pointLightIntensity
    );
    pointLight.position.set(0, 5, 0);
    scene.add(pointLight);

    const loader = new GLTFLoader();
    const modelUrl =  new URL('./../assets/Gallery-opt.glb', import.meta.url).toString();

    // DRACO Loader for optimized glb files
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderConfig({ type: 'js' });
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
    loader.setDRACOLoader( dracoLoader );

    loader.load(
        modelUrl,
        (gltf) => {
            // Option 1: Scale down the model
            const scaleFactor = 0.008; // Adjust this value as needed
            gltf.scene.scale.set(scaleFactor, scaleFactor, scaleFactor);

            scene.add(gltf.scene);

            gltf.scene.traverse((child) => {
                if (child.isMesh) {
                    let wallColor = null;
                    if (child.material.name.toLowerCase().includes("mat")) {
                        // Make "mat" look more like glass
                        child.material.opacity = 0.05;
                        child.material.color.set(0xffffff);
                    }

                    if (child.material.name.toLowerCase().includes("artwork")) {
                        child.material.color.set(0xffffff);
                    }

                    if (child.material.name.toLowerCase().includes("vault")) {
                        wallColor = child.material.color;
                    }

                    if (child.material.name.toLowerCase().includes("floor")) {
                        child.material.color = wallColor;
                        child.material.emissive = new THREE.Color(0xffffff);
                        child.material.emissiveIntensity = 0.5;
                    }
                }
            });

            const box = new THREE.Box3().setFromObject(gltf.scene);
            const center = box.getCenter(new THREE.Vector3());

            const floorY = box.min.y + 1;
            const humanHeight = 1.6 * scaleFactor; // Scale the human height
            const cameraDistance = 300 * scaleFactor; // Adjust this value to move camera further back

            // Adjust camera position to face the opposite direction and be further back
            camera.position.set(
                center.x,
                floorY + humanHeight,
                center.z + cameraDistance
            );
            camera.rotateY(Math.PI);
            controls.target.set(
                center.x,
                floorY + humanHeight,
                center.z - 1 * scaleFactor
            );

            // Update these lines
            originalCameraPosition = camera.position.clone();
            originalControlsTarget = controls.target.clone();
            
            // Set the min and max distances so we can zoom in but not out
            const initialDistance = camera.position.distanceTo(controls.target);
            controls.minDistance = initialDistance * 0.05;
            controls.maxDistance = initialDistance;

            controls.update();
        },
        (xhr) => {
            // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
            $('.model-viewer').addClass('loaded');
            $('#loading-message').hide();
        },
        (error) => {
            console.error("An error happened", error);
        }
    );

    window.addEventListener("resize", onWindowResize, false);

    function onWindowResize() {
        const width = container.clientWidth;
        const height = container.clientHeight;
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
    }

    let userInteracted = false;
    let panAngle = 50;
    const panSpeed = 0.0005;
    const panAmplitude = 1.5;

    function animate() {
        requestAnimationFrame(animate);
        controls.update();

        if (!userInteracted) {
            panAngle += panSpeed;
            camera.position.x =
                originalCameraPosition?.x ||
                0 + Math.sin(panAngle) * panAmplitude;
            camera.lookAt(controls.target);
        }

        renderer.setSize(container.clientWidth, container.clientHeight);
        renderer.render(scene, camera);
    }

    animate();
});
